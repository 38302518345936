import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base, db } from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import swal from 'sweetalert2';
import {isObjectEmpty} from 'myshared';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantRules: {},
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTenantRulesChange = this.handleTenantRulesChange.bind(this);
    }

    componentDidMount() {
        this.tenantRulesRef = base.listenToDoc(`tenantRules/default`, {
            context: this,
            then(data){
                let advanced = false;
                if(data.howToPlayText && data.howToPlayText !== "<p><br></p>"){
                    data.howToPlayText = RichTextEditor.createValueFromString(data.howToPlayText, 'html');
                    advanced = true;
                }
                if(data.howToPlayLink){
                    advanced = true;
                }
                if(data.rulesPopUpText && data.rulesPopUpText !== "<p><br></p>"){
                    data.rulesPopUpText = RichTextEditor.createValueFromString(data.rulesPopUpText, 'html');
                    advanced = true;
                }
                if(data.supportText && data.supportText !== "<p><br></p>"){
                    data.supportText = RichTextEditor.createValueFromString(data.supportText, 'html')
                }
                if(data.rulesShowInAppPopUpText && data.rulesShowInAppPopUpText !== "<p><br></p>"){
                    data.rulesShowInAppPopUpText = RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html')
                }
                if(data.rulesShowInApp){
                    advanced = true;
                }
                if(data.rulesAndRegsText && data.rulesAndRegsText !== "<p><br></p>"){
                    data.rulesAndRegsText = RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html')
                }
                this.setState({
                    tenantRules: data,
                    advanced: advanced
                })
            }
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantRulesRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        const variables = this.state.tenantRules;
        let rulesAndRegsText = variables.rulesAndRegsText || "";
        if(rulesAndRegsText){
            rulesAndRegsText = rulesAndRegsText.toString('html');
        }
        let rulesPopUpHeader = variables.rulesPopUpHeader || "";
        let supportHeader = variables.supportHeader || "";
        let rulesAndRegsLink = variables.rulesAndRegsLink || "";
        let howToPlayLink = variables.howToPlayLink || "";
        let rulesPopUpText = variables.rulesPopUpText || "";
        if(rulesPopUpText){
            rulesPopUpText = rulesPopUpText.toString('html');
        }
        let howToPlayText = variables.howToPlayText || "";
        if(howToPlayText){
            howToPlayText = howToPlayText.toString('html');
        }
        let rulesShowInAppPopUpText = variables.rulesShowInAppPopUpText || "";
        if(rulesShowInAppPopUpText){
            rulesShowInAppPopUpText = rulesShowInAppPopUpText.toString('html')
        }
        let supportText = variables.supportText || "";
        if(supportText){
            supportText = supportText.toString('html');
        }
        let rulesShowInAppPopUpHeader = variables.rulesShowInAppPopUpHeader || "";
        let rulesInAppButtonText = variables.rulesInAppButtonText || "";
        let rulesShowInApp = variables.rulesShowInApp || false;
        if(howToPlayText === "<p><br></p>"){
            howToPlayText = "";
        }
        if(rulesPopUpText === "<p><br></p>"){
            rulesPopUpText = "";
        }
        if(rulesShowInAppPopUpText === "<p><br></p>"){
            rulesShowInAppPopUpText = "";
        }
        if(supportText === "<p><br></p>"){
            supportText = "";
        }
        if(rulesAndRegsText === "<p><br></p>"){
            rulesAndRegsText = "";
        }
        let updateRulesObject = {
            "rulesAndRegsText": rulesAndRegsText, "rulesAndRegsLink": rulesAndRegsLink, "howToPlayLink": howToPlayLink,
            "howToPlayText": howToPlayText, "rulesPopUpText": rulesPopUpText, "rulesPopUpHeader":rulesPopUpHeader,
            "rulesShowInAppPopUpText": rulesShowInAppPopUpText, "rulesShowInAppPopUpHeader": rulesShowInAppPopUpHeader,
            "rulesInAppButtonText": rulesInAppButtonText, "rulesShowInApp": rulesShowInApp, "supportText": supportText,
            "supportHeader": supportHeader
        }
        console.log(updateRulesObject)
        this.setState({loading:true})
        let vm = this;
        db.collection('tenantRules').doc("default").set(updateRulesObject, {merge: true})
            .then(() => {
                vm.setState({loading:false})
                swal({
                    title: 'Rules and Regs Updated!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            }).catch(err => {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    handleRichTextChange = (name, text) => {
        let copy = this.state.tenantRules;
        copy[name] = text;
        this.setState({ tenantRules:  copy});
    }

    handleTenantRulesChange (evt) {
        let copy = this.state.tenantRules;
        let target = evt.target;
        copy[evt.target.name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ tenantRules:  copy });
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]:  value });
    }

    render() {
        const variables = this.state.tenantRules || {}
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let rulesAndRegsText = variables.rulesAndRegsText || RichTextEditor.createEmptyValue();
        let rulesAndRegsLink = variables.rulesAndRegsLink;
        let rulesPopUpHeader = variables.rulesPopUpHeader;
        let rulesPopUpText = variables.rulesPopUpText || RichTextEditor.createEmptyValue();
        let rulesInAppButtonText = variables.rulesInAppButtonText;
        let rulesShowInAppPopUpHeader = variables.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = variables.rulesShowInAppPopUpText || RichTextEditor.createEmptyValue();
        let rulesShowInApp = variables.rulesShowInApp || false;
        let howToPlayLink = variables.howToPlayLink;
        let howToPlayText = variables.howToPlayText || RichTextEditor.createEmptyValue();
        let supportHeader = variables.supportHeader;
        let supportText = variables.supportText || RichTextEditor.createEmptyValue();
        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(rulesAndRegsLink)){
            rulesAndRegsLink = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        if(isObjectEmpty(supportHeader)){
            supportHeader = ""
        }
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        const advanced = this.state.advanced || isMlbApp || false;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                        <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                        <div className="form-group">
                            <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                            {/*<input type="text" id="rulesAndRegsText" name="rulesAndRegsText" className="form-control" value={rulesAndRegsText} onChange={this.handleTenantRulesChange}/>*/}
                            <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange("rulesAndRegsText", text)}/>
                        </div>
                        {!isMlbApp &&
                            <>
                                <div className="form-group" style={{display: 'none'}}>
                                    <label htmlFor="rulesAndRegsLink">Rules And Regs Link</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>This is where your rules and regulations are hosted</p>
                                    <input id="rulesAndRegsLink" name="rulesAndRegsLink" type="url" className="form-control" value={rulesAndRegsLink} onChange={this.handleTenantRulesChange} placeholder="https://ourrules.com"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                            </>
                        }
                        {advanced &&
                            <>
                                {!isMlbApp &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="howToPlayLink">How To Play Link (Optional)</label>
                                            <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                            <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleTenantRulesChange} placeholder="https://ourgameplay.com"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                            <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                            <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={(text) => this.handleRichTextChange("howToPlayText", text)}/>
                                        </div>
                                    </>
                                }
                                <div className="form-group">
                                    <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up containing rules</p>
                                    <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleTenantRulesChange} placeholder="Rules & Regs"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                    <RichTextEditor className="max-height-400" id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text) => this.handleRichTextChange("rulesPopUpText", text)}/>
                                </div>
                                {isMlbApp &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="supportHeader">Support Header</label>
                                            <p className="text-muted2" style={{fontSize: '10px'}}>The header of the in-app pop
                                                up containing support information</p>
                                            <input id="supportHeader" name="supportHeader" type="text" className="form-control"
                                                   value={supportHeader} onChange={this.handleTenantRulesChange}
                                                   placeholder="NEED SUPPORT?"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supportText">Support Body</label>
                                            <p className="text-muted2" style={{fontSize: '10px'}}>The text of the support in-app
                                                pop up.</p>
                                            <RichTextEditor id="supportText" name="supportText" toolbarConfig={toolbarConfig}
                                                            placeholder="Email customerservice@website.mlb.com with your issue and we'll be in touch!"
                                                            value={supportText} onChange={(text) => this.handleRichTextChange("supportText", text)}/>
                                        </div>
                                    </>
                                }

                                {!isMlbApp &&
                                    <div className="form-group">
                                        <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                        <br/>
                                        <input type="checkbox" checked={rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleTenantRulesChange}/>
                                    </div>
                                }
                                {rulesShowInApp &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="rulesInAppButtonText">Button Text</label>
                                            <p className="text-muted2" style={{fontSize:'10px'}}>The text of the button that links to Rules & Regs</p>
                                            <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleTenantRulesChange} placeholder="Rules & Regs"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                            <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up</p>
                                            <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleTenantRulesChange} placeholder="Rules & Regs"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                            <p className="text-muted2" style={{fontSize:'10px'}}>The body text of the pop up</p>
                                            <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text) => this.handleRichTextChange("rulesShowInAppPopUpText", text)}/>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </form>
                </div>
              </div>
              <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
