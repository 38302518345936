import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base, db } from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'html-verify-react-rte';
import {PhotoshopPicker} from "react-color";
import DatePicker from '../utils/DatePicker';

class SetUpTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardsList: [],
            rewardLink: "",
            rewardButtonText: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            editTicketEmailImage: '',
            editTicketRevealImage: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading:true,
            files: [],
            showLosing: true,
            templateText: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.rewardsListRef = base.bindCollection(`rewardsList`, {
            context: this,
            state: 'rewardsList',
            withRefs: true,
            withIds: true,
            query: ref => {
                return ref.where('isWinning', '==', true)
            }
        });
        this.losingRewardsListRef = base.bindCollection(`rewardsList`, {
            context: this,
            state: 'losingRewardsList',
            withRefs: true,
            withIds: true,
            query: ref => {
                return ref.where('isWinning', '==', false)
            }
        });
        this.setState({loading:false})
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  [myArgument]: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    onChangeDescription = (rewardDescription) => {
        this.setState({
            rewardDescription,
        })
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.revealImagePreview.preview);
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
      base.removeBinding(this.rewardsListRef);
      base.removeBinding(this.losingRewardsListRef);
    }

    handleChange(evt){
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]:  value });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const rewardName = this.state.rewardName || "";
        const rewardDisplayName = this.state.rewardDisplayName || "";
        if(rewardName.length > 100){
            swal({
                title: 'Oh uh!',
                text: 'Prize name cannot be longer than 100 characters!',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
        }
        let rewardLink = this.state.rewardLink || "";
        let isRedeemable = this.state.isRedeemable || false;
        if(isRedeemable){
            rewardLink = "";
        }
        const vendorName = this.state.vendorName || "";
        let templateText = this.state.templateText || "";
        let subjectLine = this.state.subjectLine || "";
        const rewardLinkButtonText = this.state.rewardLinkButtonText || "";
        const rewardLinkButtonTextColor = this.state.rewardLinkButtonTextColor || "";
        const rewardLinkButtonColor = this.state.rewardLinkButtonColor || "";
        const showButton = this.state.showButton || false;
        let rewardDescription = this.state.rewardDescription.toString('html');
        if(rewardDescription === "<p><br></p>"){
            rewardDescription = "";
        }
        if(vendorName && vendorName.length > 50){
            swal({
                title: 'Oh Uh!',
                text: 'The vendor name is too long, make sure it is 50 characters or less',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(templateText && templateText.length > 300){
            swal({
                title: 'Oh Uh!',
                text: 'The template text is too long, make sure it is 300 characters or less',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(isMlbApp) {
            if(rewardLinkButtonText && rewardLinkButtonText.length > 15){
                swal({
                    title: 'Oh uh!',
                    text: 'Button text cannot be longer than 15 characters!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            let cleanRewardDescription = rewardDescription.replace(/(<([^>]+)>)/gi, "");
            if(cleanRewardDescription || vendorName) {
                let failedCheck = false;
                if(cleanRewardDescription && vendorName) {
                    const stringLength = vendorName.length + cleanRewardDescription.length;
                    if(stringLength > 469) {
                        failedCheck = true;
                    }
                } else if(cleanRewardDescription && cleanRewardDescription.length > 469) {
                    failedCheck = true;
                } else if(vendorName && vendorName.length > 469) {
                    failedCheck = true;
                }
                if(failedCheck){
                    swal({
                        title: 'Oh uh!',
                        text: 'The combination of Vendor Name and Template Text cannot be greater than 469 characters',
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
            }
        }
        let expiresAtHours = this.state.expiresAtHours || "";
        let expiresAtDateTime = this.state.expiresAtDateTime || "";
        if(expiresAtDateTime){
            if(typeof expiresAtDateTime !== "string"){
                const timeZoneOffsetHours = new Date().getTimezoneOffset()/60;
                expiresAtDateTime.setHours(expiresAtDateTime.getHours() - timeZoneOffsetHours);
                expiresAtDateTime = expiresAtDateTime.toISOString();
            }
        }
        const revealImage = this.state.revealImage || this.state.editTicketRevealImage || "";
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage || "";
        const vm = this;
        if(!rewardName && !this.state.showLosing){
            swal({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const rewardToSave = {}
        rewardToSave['rewardName'] = rewardName;
        rewardToSave['rewardDisplayName'] = rewardDisplayName;
        rewardToSave['emailImage'] = emailImage;
        rewardToSave['revealImage'] = revealImage;
        rewardToSave['rewardLink'] = rewardLink;
        rewardToSave['rewardLinkButtonText'] = rewardLinkButtonText;
        rewardToSave['rewardLinkButtonTextColor'] = rewardLinkButtonTextColor;
        rewardToSave['rewardLinkButtonColor'] = rewardLinkButtonColor;
        rewardToSave['description'] = rewardDescription;
        rewardToSave['templateText'] = templateText;
        rewardToSave['vendorName'] = vendorName;
        rewardToSave['showButton'] = showButton;
        rewardToSave['expiresAtHours'] = expiresAtHours;
        rewardToSave['expiresAtDateTime'] = expiresAtDateTime;
        rewardToSave['isWinning'] = !this.state.showLosing;
        rewardToSave['isRedeemable'] = isRedeemable;
        rewardToSave['subjectLine'] = subjectLine;
        this.setState({loading:true})
        let rewardId = (vm.state.rewardToEdit && (vm.state.rewardToEdit.key || vm.state.rewardToEdit.id)) || db.collection("rewardsList").doc().id;
        rewardToSave['id'] = rewardId;
        base.addToCollection('rewardsList', rewardToSave, rewardId)
            .then(() => {
                swal({
                    title: vm.state.rewardToEdit ? 'Prize Edited!' : 'Prize Created!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                vm.setState({
                    modal: false,
                    rewardName: '',
                    rewardDisplayName: '',
                    rewardLink: '',
                    rewardButtonColor:'',
                    subjectLine: '',
                    rewardButtonTextColor:"",
                    rewardButtonText:"",
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    vendorName: "",
                    isRedeemable: false,
                    editTicketRevealImage: '',
                    uniqueOutsideCampaignId: '',
                    expiresAtHours: null,
                    expiresAtDateTime: null,
                    editTicketEmailImage: '',
                    revealImagePreview: {},
                    emailImagePreview: {},
                    templateText: "",
                    editTicketId: '',
                    losingTicket: false,
                    editingTicket: false,
                    showButton: false,
                    loading: false,
                    emailImage: ''
                })
            }).catch(err => {
                vm.setState({loading:false})
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
                console.log(err)
        })
    }

    switchTicketsShowing(){
        if(this.state.showWinning){
            this.setState({
                showWinning:false,
                showLosing:true
            }, () => {
                document.getElementById('showWinningButton').classList.remove('active');
                document.getElementById('showLosingButton').classList.add('active');
            })
        }else{
            this.setState({
                showWinning:true,
                showLosing:false
            }, () => {
                document.getElementById('showLosingButton').classList.remove('active');
                document.getElementById('showWinningButton').classList.add('active');
            })
        }
    }

    editTicket(event){
      event.preventDefault();
      const array = this.state.rewardsList;
      const prizeId = event.currentTarget.getAttribute('value');
      let prize = false;
      for(let item in array){
          if(array[item].id === prizeId){
              prize = array[item]
          }
      }
      if(!prize){
        swal({
            title: 'There was some error!',
            text: 'Try again and if the problem persists try logging out and logging back in',
            type: 'error',
            confirmButtonText: 'Ok'
        })
          return;
      }
      let advanced = false;
      if(prize.uniqueOutsideCampaignId || prize.expiresAtHours || prize.expiresAtDateTime){
          advanced = true
      }
      this.setState({
          modal: true,
          rewardName: prize.rewardName,
          rewardDisplayName: prize.rewardDisplayName,
          uniqueOutsideCampaignId: prize.uniqueOutsideCampaignId,
          rewardLink: prize.rewardLink,
          rewardDescription: RichTextEditor.createValueFromString(prize.description, 'html'),
          rewardLinkButtonText:prize.rewardLinkButtonText,
          rewardLinkButtonColor:prize.rewardLinkButtonColor || "#fc2e2e",
          rewardLinkButtonTextColor:prize.rewardLinkButtonTextColor || "#ffffff",
          vendorName: prize.vendorName || "",
          editTicketEmailImage:prize.emailImage,
          editTicketRevealImage:prize.revealImage,
          expiresAtHours: prize.expiresAtHours,
          expiresAtDateTime: prize.expiresAtDateTime,
          templateText: prize.templateText,
          rewardToEdit: prize,
          advanced: advanced,
          editTicketId: prize.id,
          showButton: prize.showButton,
          editingTicket: true,
          isRedeemable: prize.isRedeemable,
          subjectLine: prize.subjectLine
      });
    }

    deleteTicket(e){
      e.preventDefault();
      const rewardId = e.currentTarget.getAttribute('value');
      swal({
            title: 'Delete Prize?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this prize in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.removeDoc('rewardsList/' + rewardId)
                .then(() => {
                    console.log("DELETED SUCCESSFULLY")
                }).catch(err => {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
          }
        })
    }

    toggle() {
      this.setState({
          modal: !this.state.modal,
          losingTicket: this.state.showLosing,
          editingTicket: false,
          advanced: false,
          rewardName: '',
          rewardLink: '',
          rewardLinkButtonText: "",
          rewardLinkButtonColor: "",
          rewardLinkButtonTextColor: "",
          rewardDisplayName: "",
          rewardDescription: RichTextEditor.createEmptyValue(),
          revealImage: null,
          vendorName: "",
          templateText: "",
          editTicketRevealImage: '',
          uniqueOutsideCampaignId: '',
          expiresAtHours: null,
          expiresAtDateTime: null,
          editTicketEmailImage: '',
          revealImagePreview: {},
          emailImagePreview: {},
          editTicketId: '',
          subjectLine: '',
          rewardToEdit: null,
          showButton: false,
          isRedeemable: false,
          emailImage: ''
      });
    }

    render() {
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        let rewardLinkLabelText = "Prize Link Instead Of Coupon Image";
        const winningTicketList = this.state.rewardsList;
        const losingTicketList = this.state.losingRewardsList || [];
        const emailImagePreview = this.state.emailImagePreview;
        const revealImagePreview = this.state.revealImagePreview;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS');
            rewardLinkLabelText = "Prize Link Optional (Makes the email image clickable)";
        }
        let expiresAtDateTime;
        if(this.state.expiresAtDateTime){
            expiresAtDateTime = new Date(this.state.expiresAtDateTime)
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                        <li className="nav-item" onClick={()=> this.switchTicketsShowing()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showLosingButton">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Losing</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.switchTicketsShowing()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showWinningButton">
                                <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Winning</span>
                            </a>
                        </li>
                    </ul>
                    <div style={{display: !this.state.showWinning ? 'block' : 'none'}}>
                        <p className="admin-header-text" style={{marginBottom:0}}>Losing</p>
                        <p className="admin-subheader-text">This is what fans will see when they scratch a losing card</p>
                        <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                            {
                                losingTicketList.map(function(item,i){
                                    return <div key={i} className="card">
                                        <div className="card-body" align="center">
                                            <p>
                                                <img width='80%' height='auto' src={item.revealImage} alt=""/>
                                            </p>
                                            <button className="btn btn-primary btn-lg delete-button" style={{ marginTop: 5}} onClick={this.deleteTicket.bind(this)} value={item.id}><span className="fa fa-trash-o"/> Delete</button>
                                        </div>
                                    </div>
                                }, this)
                            }
                        </div>
                    </div>
                    <div style={{display: this.state.showWinning ? 'block' : 'none'}}>
                        <p className="admin-header-text" style={{marginBottom:0}}>Winning</p>
                        <p className="admin-subheader-text">These are prizes fans will reveal when they scratch a winning card</p>
                        <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                            {
                                winningTicketList.map(function(item,i){
                                    return <div key={i} className="card">
                                        <div className="card-body" align="center">
                                            <p style={{marginTop:5}}>{item.rewardName}</p>
                                            <p>
                                                <img width="80%" height="auto" src={item.revealImage} alt=""/>
                                            </p>
                                            <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={item.id}><span className="fa fa-ellipsis-v"/> Edit</button>
                                            <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={item.id}><span className="fa fa-trash-o"/> Delete</button>
                                        </div>
                                    </div>
                                }, this)
                            }
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <ModalHeader toggle={this.toggle} style={{display: !this.state.losingTicket ? 'block' : 'none' }}>Add Winning</ModalHeader>
                <ModalHeader toggle={this.toggle} style={{display: this.state.losingTicket ? 'block' : 'none' }}>Add Losing</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form" name="createEditForm">
                          <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                              <label htmlFor="rewardName">Prize Name</label>
                              <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                          </div>
                          {!isMlbApp &&
                              <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                  <label htmlFor="rewardDisplayName">Prize Display Name (Optional)</label>
                                  <input id="rewardDisplayName" name="rewardDisplayName" type="text" className="form-control" value={this.state.rewardDisplayName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                              </div>
                          }

                          {!isMlbApp &&
                              <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                  <label htmlFor="subjectLine">Prize Email Subject Line (Optional)</label>
                                  <input id="subjectLine" name="subjectLine" type="text" className="form-control" value={this.state.subjectLine} onChange={this.handleChange} placeholder="You Have Won!"/>
                              </div>
                          }

                          <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                              <label htmlFor="rewardLink">{rewardLinkLabelText}</label>
                              <span> OR </span>
                              <label htmlFor="showRewardsOutMessage"> Sqwad Redeem Process</label>
                              <input type="checkbox" checked={this.state.isRedeemable} id="isRedeemable" name="isRedeemable" onChange={this.handleChange}/>
                              {!this.state.isRedeemable &&
                                  <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                              }
                          </div>
                          {this.state.rewardLink && !isMlbApp &&
                              <div className="form-group">
                                  <label htmlFor="showButton">Show Button</label>
                                  <br/>
                                  <input type="checkbox" checked={this.state.isRedeemable || this.state.showButton} id="showButton" name="showButton" onChange={this.handleChange} disabled={this.state.isRedeemable && true}/>
                              </div>
                          }
                          {((this.state.rewardLink && this.state.showButton) || isMlbApp || this.state.isRedeemable) &&
                              <>
                                  <div className="form-group" style={{display: !this.state.losingTicket && (this.state.rewardLink || isMlbApp || this.state.isRedeemable) ? '' : 'none' }}>
                                      <label htmlFor="rewardLinkButtonText">Button Text</label>
                                      <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder={this.state.isRedeemable && !isMlbApp?"Store Redeem":"GET MY PRIZE!"}/>
                                  </div>
                                  {/*<div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>*/}
                                  {/*    <label htmlFor="rewardLinkButtonText">Prize Link Button Text (Optional)</label>*/}
                                  {/*    <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder="Reward Link"/>*/}
                                  {/*</div>*/}
                                  {!isMlbApp &&
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                                  <span style={{marginRight: 10}}
                                                        className="fa fa-eyedropper mobile-hide"
                                                        onClick={() => this.openColorPicker("rewardLinkButtonTextColor")}/>
                                                  <div style={{
                                                      backgroundColor: this.state.rewardLinkButtonTextColor,
                                                      marginRight: 10,
                                                      border: "solid 1px",
                                                      minHeight: 20,
                                                      width: 20,
                                                      display: "inline-block"
                                                  }}/>
                                                  <label htmlFor="rewardLinkButtonTextColor" style={{marginRight: 10}}>Button
                                                      Text Color</label>
                                                  <input id="rewardLinkButtonTextColor" name="rewardLinkButtonTextColor"
                                                         type="text" className="form-control"
                                                         value={this.state.rewardLinkButtonTextColor}
                                                         onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                                  <span style={{marginRight: 10}}
                                                        className="fa fa-eyedropper mobile-hide"
                                                        onClick={() => this.openColorPicker("rewardLinkButtonColor")}/>
                                                  <div style={{
                                                      backgroundColor: this.state.rewardLinkButtonColor,
                                                      marginRight: 10,
                                                      border: "solid 1px",
                                                      minHeight: 20,
                                                      width: 20,
                                                      display: "inline-block"
                                                  }}/>
                                                  <label htmlFor="rewardLinkButtonColor" style={{marginRight: 10}}>Button
                                                      Color</label>
                                                  <input id="rewardLinkButtonColor" name="rewardLinkButtonColor"
                                                         type="text" className="form-control"
                                                         value={this.state.rewardLinkButtonColor}
                                                         onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                      </div>
                                  }
                              </>
                          }
                          {isMlbApp &&
                              <div style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                  <div className="form-group">
                                      <label htmlFor="vendorName">Vendor Name</label>
                                      <input id="vendorName" name="vendorName" type="text" className="form-control" value={this.state.vendorName} onChange={this.handleChange} placeholder="Your sponsors name"/>
                                  </div>
                              </div>
                          }
                          <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                              {/*{isMlbApp && <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text should finish the phrase: You have an update from </p>}*/}
                              {isMlbApp ?
                                  <div className="form-group">
                                      <label htmlFor="templateText">Template Text</label>
                                      <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This text should finish the phrase: You have an update from </p>
                                      <input id="templateText" name="templateText" className="form-control" value={this.state.templateText} onChange={this.handleChange} />
                                  </div>
                                  :
                                  <>
                                      <label htmlFor="rewardDescription">{isMlbApp? "Template Text" : "Prize Description"}: </label>
                                      <RichTextEditor toolbarClassName={isMlbApp && "hide_toolbar_rich_text"} toolbarConfig={toolbarConfig} id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder={isMlbApp?"your favorite team":"My Sweet Prize Description"}/>
                                  </>
                              }
                          </div>
                          {isMlbApp && !this.state.losingTicket &&
                              <>
                                  <div className="form-group">
                                      <label htmlFor="showRewardsOutMessage">Advanced</label>
                                      <br/>
                                      <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                  </div>
                                  <div style={{display: this.state.advanced && !isMlbApp ? "" : "none"}}>
                                      <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                          <label htmlFor="uniqueOutsideCampaignId">Unique Id For An Outside System</label>
                                          <input id="uniqueOutsideCampaignId" name="uniqueOutsideCampaignId" type="text" className="form-control" value={this.state.uniqueOutsideCampaignId} onChange={this.handleChange} placeholder="Unique Id"/>
                                      </div>
                                  </div>
                                  {this.state.advanced && isMlbApp && !this.state.losingTicket &&
                                      <>
                                          <div className="form-group">
                                              <label htmlFor="expiresAtHours">Hours Until Expire</label>
                                              <input id="expiresAtHours" name="expiresAtHours" step="1" type="number" className="form-control" value={this.state.expiresAtHours} onChange={this.handleChange} placeholder="720"/>
                                          </div>
                                          OR/AND
                                          <div className="form-group row mb-3 form_times">
                                              <label className="col-md-3 col-form-label" htmlFor="expiresAtDateTime"> Date Until Expire</label>
                                              <div className="col-md-9">
                                                  <DatePicker showTimeInput dateFormat="Pp" selected={expiresAtDateTime} onChange={date => this.setState({expiresAtDateTime: date})}/>
                                              </div>
                                          </div>
                                      </>
                                  }
                              </>
                          }
                          <div className="form-group" align="center">
                              <label htmlFor="rewardRevealImage" style={{width:'100%'}}>Reveal Image</label>
                              <img src={this.state.editTicketRevealImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                              <Dropzone
                                  className="dropzone dz-clickable"
                                  accept="image/*"
                                  onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'revealImage')}}
                                  multiple={false}
                                  maxSize={5242880}>
                                  <div className="dz-message needsclick">
                                      <span className="fa fa-cloud-upload text-muted" style={{display: revealImagePreview.preview ? 'none' : ''}}/>
                                      <h3 style={{display: revealImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                      <img
                                          style={{display: revealImagePreview.preview ? '' : 'none'}}
                                          src={revealImagePreview.preview}
                                          width="100px"
                                          height="100auto"
                                          alt="Drop your image here"
                                      />
                                  </div>
                              </Dropzone>
                          </div>
                          <div className="form-group" align="center" style={{display: !this.state.losingTicket ? 'block' : 'none' }}>
                              <label htmlFor="rewardEmailImage" style={{width:'100%'}}>Prize {isMlbApp ? "Message" : "Email"} Image</label>
                              <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                              <Dropzone
                                  className="dropzone dz-clickable"
                                  accept="image/*"
                                  onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                                  multiple={false}
                                  maxSize={5242880}>
                                  <div className="dz-message needsclick">
                                      <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                                      <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                      <img
                                          style={{display: emailImagePreview.preview ? '' : 'none'}}
                                          src={emailImagePreview.preview}
                                          width="100px"
                                          height="auto"
                                          alt="Drop your image here"
                                      />
                                  </div>
                              </Dropzone>
                          </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTickets
